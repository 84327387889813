<template>
  <div class="wx-packet">
    <Packet source="wx" @openApp="openApp"></Packet>
    <div class="cover" v-if="cover">
      <img class="cover-img" src="https://cdn.cailu88.com/jingxuanshi/client_home_guide.png"/>
    </div>
  </div>
</template>
<script>
import Packet from './components/helpPacket'
import baseUrl from '@/utils/baseUrl'
const api = require('../../utils/api').api

export default {
  name: 'wxPacket',
  data () {
    return {
      cover: false,
      taskId: ''
    }
  },
  created () {
    this.taskId = this.$route.query.taskId
    this.cover = this.$store.state.isAndroidApp && this.$store.state.isWx ? true : false
  },
  mounted () {
    // 注入友盟js
    const script = document.createElement('script')
    script.src = 'https://g.alicdn.com/jssdk/u-link/index.min.js'
    script.language = 'JavaScript'
    document.body.appendChild(script)
  },
  components: {
    Packet
  },
  methods: {
    openApp () {
      this.$axios(api.taskShareUrl, {
        params: {
          taskId: this.taskId
        }
      }).then(res => {
        // const cParams = ULink.getUriParams().c
        ULink.start({
          id: 'usr1f3p1nlg6ola2', /* 平台为每个应用分配的方案Ulink ID，必填 */
          data: {
            'c': res.data.data.c,
            'custom': res.data.data.custom
          } /* 自定义参数，选填 */
        }).ready(function (ctx) { /* 初始化完成的回调函数 */
          ctx.wakeup() /* 用户点击某个按钮时启动app */
        })
      }).catch(err => {
        console.info(err)
        this.isLogin()
      })
    },
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/packet/choose`
      }
      this.$h5AppLink(event, param)
    }
  }
}
</script>
<style lang="less" scoped>
.wx-packet {
  .cover {
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
    width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
    height: 100%;
    filter: alpha(opacity=100); /*设置透明度为100%*/
    opacity: 1; /*非IE浏览器下设置透明度为100%*/
    display: block;
    z-Index: 999;
    .cover-img {
      width: 100%;
    }
  }
}
</style>
